import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/college/page',
    update:'/admin/v1/college/update',
    create:'/admin/v1/college/create',
    del:'/admin/v1/college/del',
    get_college:'/admin/v1/college/get'
}


export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

export async function get_college(data)
{
    return $h.request(url.get_college,data,'GET')
}